import React from 'react'

function Countdown({timerDays, timerHours, timerMinutes, timerSeconds}) {
  return (
    <div className="event-countdown-wrapper">
        <div className="event-countdown-box">
            <h3>{timerDays}</h3>
            <p>Nap</p>
        </div>
        <div className="event-countdown-box"> 
            <h3>{timerHours}</h3>
            <p>Óra</p>
        </div>
        <div className="event-countdown-box">
            <h3>{timerMinutes}</h3>
            <p>Perc</p>
        </div>
        <div className="event-countdown-box">
            <h3>{timerSeconds}</h3>
            <p>Másodperc</p>
        </div>

    </div>
  )
}

export default Countdown