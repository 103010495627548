import './App.css';
import { Router, Route, Routes } from "react-router-dom";
import Homepage from './pages/Homepage';
import Registration from './pages/Registration';
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  return (
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Homepage/>}/>
          <Route path="/regisztracio" element={<Registration/>}/>
        </Routes>
      </ScrollToTop>
  );
}

export default App;
