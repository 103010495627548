import React from 'react';


function Footer() {
  return (
    <>
      <div className='footer'>
        <div className='footer-wrapper'>
          <div className="column">
            <img src={require("../Footer/szhely2030.png")} alt="szhely2030" width="100%"/>
            <p>Szombathely2030 program célja a város és térsége életszínvonalának emelése, a minőségi környezet biztosítása a lakosok és vállalkozások számára, illetve a versenyképesség fokozása, a modern városi lét ösztönzése</p>
          </div>
          <div className="column">
            <h2>Projektfinanszírozás</h2>
            <p>A projekt az Interreg Europe Programban az Európai Unió és Magyarország társfinanszírozásával valósul meg.</p>
            <img src={require("../Footer/foundation.png")} alt="foundation" width="100%"/>
          </div>
          <div className="column">
            <h2>Közösségi média</h2>
            <div><a href="https://szombathely2030.hu/wp-content/uploads/2021/04/Honlap-adatvedelmi-tajekoztato-szombathely2030-converted.pdf" target='_blank'>Adatvédelmi nyilatkozat</a></div>
            <div><a href="https://www.facebook.com/events/494468022030918" target='_blank'><img src={require("../Footer/facebook.png")} alt="foundation" width="50px"/></a></div>
          </div>
          <div className="column">
            <h2>Elérhetőségek</h2>
            <div><h4>Éva Doroti</h4></div>
            <div><p>projektmenedzser</p></div>
            <div><a href="mailto:doroti.eva@pbn.hu">doroti.eva@pbn.hu</a></div>
            <div><a href="tel:+36203345312">+36 20 334 5312</a></div>
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className='footer-info'>
          <div><h3>A rendezvény szervezői és támogatói:</h3></div>
          <div><img src={require("../Footer/sponsors.png")} alt="foundation" width="100%"/></div>
          <div><p>Minden jog fenntartva. © Pannon Gazdasági Hálózat Egyesület 2022.</p></div>
        </div>

      </div>
    
    </>
  )
}

export default Footer