import React, { useState, useEffect } from 'react';
import Countdown from '../eventCountdown/Countdown';

function EventPromotion() {

  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  let interval;

  const startTimer = () =>{
    const countDownDate = new Date("October 11, 2022").getTime();

    interval = setInterval(() =>{
      const now = new Date().getTime();

      const distance = countDownDate - now

      const days = Math.floor(distance / (24*60*60*1000))
      const hours = Math.floor(distance % (24*60*60*1000) / (1000*60*60))
      const minutes = Math.floor(distance % (60*60*1000) / (1000*60))
      const seconds = Math.floor(distance % (60*1000) / (1000))

      if (distance < 0) {

        clearInterval(interval.current);

        } else {

        setTimerDays(days)
        setTimerHours(hours)
        setTimerMinutes(minutes)
        setTimerSeconds(seconds)
        
      }

    })
  };


  useEffect(() => {
    startTimer()
  })
  return (
    <div className='event-promotion-wrapper'>
        <div className="event-promotion-info">
            <div>
                <h3>{"2022. október 11. 9:00 - 16:00 / Agora Művelődési és Sportház"}</h3>
                <h1>Egészségben a jövő</h1>
                <h2>Konferencia és Startup verseny</h2>
            </div>
        </div>
        
        <div className="event-promotion-info">
        <Countdown timerDays={timerDays} timerHours={timerHours} timerMinutes={timerMinutes} timerSeconds={timerSeconds}/>
        </div>

    </div>
  )
}

export default EventPromotion