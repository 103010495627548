import React from 'react';
import StartupRegistration from '../components/startupRegistration/StartupRegistration';
import Footer from '../components/Footer/Footer'

function Registration(props) {
  return (
    <div>
        <StartupRegistration />
        <Footer />
    </div>
  )
}

export default Registration