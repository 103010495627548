import React from 'react';
import EventTable from '../EventTable/EventTable';


function EventInformation() {
  return (
    <div className='wrapper'>
      <div className="event-information-wrapper">
          <div className='column'>
              <h2>Az eseményről</h2>
              <p className='justified'>
              2022. október 11-én kerül megrendezésre az Egészségben a jövő - II.
              Szombathely2030 Konferencia, immár hagyományosan a Budapest Design Hét
              rendezvénysorozat égisze alatt, vidéki rendezvényhelyszínként.</p>
              <p>
              A program első részében az egészségipar várható helyzetéről hallhat szakmai
              előadásokat a közönség, Szombathely és Vas megye perspektívájából. Ezt követően a
              tématerületen már sikereket elért start-up vállalkozások bemutatkozására kerül sor,
              majd a nap fénypontjaként megvalósul a város első start-up versenye. 
              </p>
              <p>
              A rendezvény utolsó részében lehetőség nyílik megtekinteni az egészségipari témában megjelenő
              helyi és országos jelentőségű kiállítók standjait.
              </p>
              <h2>A rendezvény célja</h2>
              <ul>
                  <li>Elősegíteni a város és térsége egészségipari fejlesztési orientációját a Szombathely2030 stratégiai programmal összhangban</li>
                  <li>Felhívni a társadalom szélesebb rétegeinek figyelmét az egészségipar fontosságára</li>
                  <li>Új vállalkozói ötletek felkarolása és támogatása az egészségiparban</li>
              </ul>
          </div>
      </div>
          <div className='table-wrapper'>
            <EventTable/>
          </div>
    </div>
  )
}

export default EventInformation