import React from 'react'

function StartupRegistration() {
  return (
    <div>
        <div className='wrapper'>
            <div className="form-wrapper">
                <div className="form">
                    <form action="https://getform.io/f/6973bc6c-ac48-44e4-89ca-9fdfc6ffa05a" method="POST" encType="multipart/form-data">
                    <h1>Pályázati anyag leadása</h1>
                    <p>A pályázat beadásának határideje: <strong>{"2022. szeptember 26. (éjfél)."}</strong></p>
                    <div className="formbox">
                        <h2>1. Alapadatok</h2>
                        <p className='formsubtitle'>Minden mező kitöltése <strong>{"kötelező!"}</strong></p>
                        <label htmlFor="teamName">Csapat neve:</label><br></br>
                        <input required type="text" name="teamName"/><br></br>

                        <label htmlFor="leaderName">Csapat képviselőjének a neve:</label><br></br>
                        <input required type="text" name="leaderName"/><br></br>

                        <label htmlFor="leaderPosition">Képviselő pozíciója:</label><br></br>
                        <input required type="text" name="leaderPosition"/><br></br>
                
                        <label htmlFor="leaderPhone">Képviselő e-mail címe:</label><br></br>
                        <input required type="email" name="email" /><br></br>

                        <label htmlFor="teamMembers">{"Csapattagok nevei (amennyiben többtagú):"}</label><br></br>
                        <input required type="text" name="teamMembers"/><br></br>

                        <label htmlFor="teamIntroduction">Csapat rövid leírása:</label><br></br>
                        <textarea required name="teamIntroduction" rows="5" cols="50"></textarea>

                    </div>

                    <hr/>
                    <div className='formbox'>
                        <h2>2. Vállalkozói ötlet vagy megoldás leírása</h2>
                        <p className='formsubtitle'>Minden mező kitöltése <strong>{"kötelező!"}</strong></p>
                        
                        <label htmlFor="businessIdea">Vállalkozói ötlet rövid bemutatása:</label><br></br>
                        <textarea required name="businessIdea" rows="5" cols="50" maxlength="1000"></textarea>

                        <label htmlFor="businessTarget">Célcsoport ismertetése:</label><br></br>
                        <textarea required name="businessTarget" rows="5" cols="50" maxlength="1000"></textarea>

                        <label htmlFor="businessMarket">Versenytársak, piaci helyzet bemutatása:</label><br></br>
                        <textarea required name="businessMarket" rows="5" cols="50" maxlength="1000"></textarea>

                        <label htmlFor="businessResources">Rendelkezésre álló erőforrások ismertetése:</label><br></br>
                        <textarea required name="businessResources" rows="5" cols="50" maxlength="1000"></textarea>

                        <label htmlFor="businessFinances">Pénzügyi terv:</label><br></br>
                        <textarea required name="businessFinances" rows="5" cols="50" maxlength="1000"></textarea>

                        <label htmlFor="businessRisks">Kockázatok:</label><br></br>
                        <textarea required name="businessRisks" rows="5" cols="50" maxlength="1000"></textarea>
                    </div>

                    <div className="formbox">
                        <h2>{"3. Mellékletek feltöltése (max. 25 MB)"}</h2>
                        <input required type="file" name="fileUpload"/><br></br>
                    </div>

                    <div>
                        <input required type="checkbox" name="checkbox"/>
                        <label htmlFor="checkbox"> Megismertem és elfogadom az <a className="link" href="../documents/adatkezelesi.pdf" target="_blank">Adatkezelési tájékoztatóban</a> foglaltakat.</label>
                    </div>

                    <div className="form-button">
                        <button className="default-btn" type="submit">Pályázat beküldése</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default StartupRegistration