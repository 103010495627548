import React from 'react';
import { Link } from 'react-router-dom';

function StartupInformation() {
  return (
    <div className='startup-section'>
        <div className="startup-information-wrapper">
            <div className="column">
                <h2>
                    Jelentkezés a Startup versenyre!
                </h2>
                <p className='justified'>
                    Az „Egészségben a jövő” ötletversenyre olyan <strong>start-up vállalkozások, induló
                    vállalkozások, egyetemi csapatok, illetve magánemberek</strong> jelentkezését várjuk, akik
                    az egészségipar, egészségügy területein innovatív ötlettel
                    vagy megoldással rendelkeznek, amely válaszként szolgálhat egy meglévő
                    problémára, előrelépést jelent egy korábbi technológiához képest, vagy valamilyen
                    módon képes hozzájárulni az iparág fejlődéséhez.
                </p>
                <h3>
                    Részletek az <a className="link" href="../documents/felhivas.pdf" target="_blank"> alábbi felhívásban</a> találhatók.
                </h3><br></br>
                <Link className="default-btn" to="/regisztracio">Pályázat beadása</Link>
            </div>
            <div className="column">
                <h2>
                    Fontos információk:
                </h2>
                <ul>
                    <li>Pályázati anyagok benyújtási határideje:<br></br><strong>2022. szeptember 26.</strong><br></br><strong><h4>Meghosszabbítva: 2022. október 2.</h4></strong></li>
                    <li>Pályázati anyagok elbírálása:<br></br> <strong>2022. szeptember 30.</strong><br></br><strong><h4>Meghosszabbítva: 2022. október 4.</h4></strong></li>
                    <li>A döntő személyesen kerül megrendezésre:<br></br> <strong>2022. október 11. 9:30 – 13:30-ig</strong></li>
                    <li>A döntő helyszíne:<br></br><strong>Agora – Művelődési és Sportház,<br></br>Szombathely, Március 15. tér 5.</strong><br></br></li>
                </ul>
                
            </div>
        </div>
    </div>
  )
}

export default StartupInformation