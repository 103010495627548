import React from 'react';
import EventInformation from '../components/eventInformation/EventInformation';
import EventLinks from '../components/EventLinks/EventLinks';
import EventPromotion from '../components/eventPromotion/EventPromotion';
import StartupInformation from '../components/StartupInformation/StartupInformation';
import StartupRegistration from '../components/startupRegistration/StartupRegistration';
import VisitorRegistration from '../components/visitorRegistration/VisitorRegistration';
import Footer from '../components/Footer/Footer'

function Homepage(props) {
  return (
    <div>
        <EventPromotion />
        <EventInformation />
        <StartupInformation />
        <VisitorRegistration />
        <Footer />
    </div>
  )
}

export default Homepage