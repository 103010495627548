import React from 'react'

function EventTable() {
  return (
    <table >
        <thead>
            <tr>
                <th colSpan="2">Programtábla</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className='timestamp'>9:00 </td>
                <td className="eventname">Regisztráció</td>
            </tr>
            <tr>
                <td className='timestamp'>9:30</td>
                <td className="eventname"> Köszöntő</td>
            </tr>
            <tr>
                <td className='timestamp'>9:40</td>
                <td>
                <span className='eventname'>Egészségipari kitekintés - Szakmai előadások</span><br></br>
                • Az egészségipar jövője, új trendjei<br></br>
                • Helyi egészségipari vállalkozások fejlesztései
                </td>
            </tr>
            <tr>
                <td className='timestamp'>10:10</td>
                <td>
                <span className='eventname'>Hogyan vigyük sikerre vállalkozói ötletünk?</span><br></br>
                • A régióból indult startup vállalkozások fejlődéstörténetének bemutatása<br></br>
                </td>
            </tr>
            <tr>
                <td className='timestamp'>11:00</td>
                <td>
                <span className='eventname'>Vállalkozói ötletverseny az egészségipari innováció területén</span><br></br>
                • Startup-ok, egyetemi csapatok ötleteinek bemutatása<br></br>
                • Szakmai zsűri kérdéseinek megválaszolása<br></br>
                • Közönségszavazás<br></br>
                </td>
            </tr>
            <tr>
                <td className='timestamp'>12:00</td>
                <td>
                <span className='eventname'>{"Kiállítás megnyitása & Ebéd"}</span><br></br>
                • Helyi és országos jelentőségű kiállítók az egészségipar területéről<br></br>
                • Catering kuponos rendszerben<br></br>
                </td>
            </tr>
            <tr>
                <td className='timestamp'>13:00</td>
                <td>
                <span className='eventname'>{"Eredményhirdetés & Tombolasorsolás"}</span><br></br>
                </td>
            </tr>
            <tr>
                <td className='timestamp'>13:30 -<br></br>15:00</td>
                <td>
                <span className='eventname'>Kiállítás megtekintése</span><br></br>
                </td>
            </tr>

        </tbody>
    </table>
  )
}

export default EventTable