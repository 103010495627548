import React from 'react'

function VisitorRegistration() {
  return (
    <div className='wrapper'>
        <div className="form-wrapper">
            <div className="form">
                <form action="https://getform.io/f/703e789b-3c16-48f7-8191-539ef19fd20e" method="POST" encType="multipart/form-data">
                    <div className='formbox'>
                        <h2>Nézői regisztráció</h2>
                        <p className='formsubtitle'>A rendezvény nyilvános. A részvétel ingyenes, de <strong>előzetes regisztrációhoz kötött!</strong></p>
                        <label htmlFor="preName">Név előtag:</label><br></br>
                        <input type="text" name="preName" placeholder="pl. Dr."/><br></br>

                        <label htmlFor="lastName">Vezetéknév:</label><br></br>
                        <input required type="text" name="lastName" placeholder="Minta"/><br></br>

                        <label htmlFor="firstName">Keresztnév:</label><br></br>
                        <input required type="text" name="firstName" placeholder="János"/><br></br>

                        <label htmlFor="lastName">Szervezet neve:</label><br></br>
                        <input type="text" name="companyName" placeholder="Szervezet neve (ha van)"/><br></br>
                
                        <label htmlFor="lastName">E-mail cím:</label><br></br>
                        <input required type="email" name="email" placeholder="mintajanos@gmail.com"/><br></br>

                        <div>
                            <input required type="checkbox" name="checkbox"/>
                            <label htmlFor="checkbox"> Megismertem és elfogadom az <a className="link" href="../documents/adatkezelesi.pdf" target="_blank">Adatkezelési nyilatkozatban</a> foglaltakat.</label>
                        </div>

                        <div className="form-button">
                            <button className="default-btn" type="submit">Regisztráció</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default VisitorRegistration